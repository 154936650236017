import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayoffData, StudentLoanAssistanceData } from 'api/StudentLoanApi';
import { getStudentLoanAssistanceData, updateStudentLoanAssistanceData } from 'thunks';

interface StudentLoanDataFetchStatus {
  isLoading: boolean;
  error: boolean;
  fetched: boolean;
  refetch: boolean;
}
export interface StudentLoanData {
  applicationId?: string;
  eligible?: boolean;
  paymentStartDate?: string;
  forProfit?: boolean;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  currentPath?: PayoffData;
  recommendedPath?: PayoffData;
  householdSize?: number;
  undergraduateLoansPercentage?: number;
  enrolledIncomeDrivenRepayment?: boolean;
}

type StudentLoanDataState = StudentLoanData & StudentLoanDataFetchStatus;

const initialState: StudentLoanDataState = {
  isLoading: false,
  error: false,
  fetched: false,
  refetch: false,
};

const updateState = (state: StudentLoanDataState, { payload }: PayloadAction<StudentLoanAssistanceData>) => {
  state.isLoading = false;
  state.fetched = true;

  state.eligible = payload.eligible;
  state.paymentStartDate = payload.paymentStartDate;
  state.maxTerm = payload.maxTerm;
  state.totalBalance = payload.totalBalance;
  state.monthSavings = payload.monthSavings;
  state.currentPath = payload.currentPath;
  state.recommendedPath = payload.recommendedPath;
  state.householdSize = payload.householdSize;
  state.undergraduateLoansPercentage = payload.undergraduateLoansPercentage;
  state.enrolledIncomeDrivenRepayment = payload.enrolledIncomeDrivenRepayment;
};

const auth = createSlice({
  name: 'studentLoanData',
  initialState,
  reducers: {
    setPaymentStartDate: (state: StudentLoanData, { payload }: PayloadAction<string>) => {
      state.paymentStartDate = payload;
    },
    setForProfit: (state: StudentLoanData, { payload }: PayloadAction<boolean>) => {
      state.forProfit = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentLoanAssistanceData.pending, (state, action) => {
      state.applicationId = action.meta.arg.applicationId;
      state.refetch = state.fetched;
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(getStudentLoanAssistanceData.fulfilled, updateState);
    builder.addCase(getStudentLoanAssistanceData.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.fetched = false;
      state.eligible = false;
    });
    builder.addCase(updateStudentLoanAssistanceData.pending, (state) => {
      state.refetch = state.fetched;
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(updateStudentLoanAssistanceData.fulfilled, updateState);
    builder.addCase(updateStudentLoanAssistanceData.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setPaymentStartDate, setForProfit } = auth.actions;

export default auth.reducer;

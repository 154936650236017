import React from 'react';
import { PayoffData } from 'api/StudentLoanApi';
import { isBefore } from 'date-fns';
import { StudentLoanData } from 'handlers/studentLoanData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

export const getRepaymentLabel = (
  monthDiff: number,
  currentPath: PayoffData,
  recommendedPath: PayoffData,
): {
  headline: JSX.Element;
  value: string;
} => {
  let headline = (
    <>
      Act Now! <br />
      You could be out of debt
    </>
  );
  const { monthsToDebtFree } = recommendedPath;

  if (monthsToDebtFree < 0) return { headline, value: 'Today' };
  const monthsUntilEndOfYear = 12 - new Date().getMonth();
  if (monthsToDebtFree < monthsUntilEndOfYear) return { headline, value: 'This year' };
  if (monthsToDebtFree < monthsUntilEndOfYear + 12) return { headline, value: 'Next year' };

  const { monthlyPayment: currentPathMonthlyPayment, totalPayment: currentPathTotalPayment } = currentPath;
  const { monthlyPayment: recommendedPathMonthlyPayment, totalPayment: recommendedPathTotalPayment } = recommendedPath;

  const monthlyPaymentSavings = currentPathMonthlyPayment! - recommendedPathMonthlyPayment!;
  if (monthlyPaymentSavings >= 100) {
    return {
      headline: <>Act Now! You could reduce your payment by</>,
      value: `${formatMonetaryAmount(monthlyPaymentSavings)} per month`,
    };
  }

  const totalPaymentSavings = currentPathTotalPayment! - recommendedPathTotalPayment!;
  if (totalPaymentSavings >= 10_000) {
    headline = (
      <>
        Act Now!
        <br />
        You Could Save
      </>
    );
    return {
      headline,
      value: formatMonetaryAmount(totalPaymentSavings),
    };
  }

  if (monthDiff < 12)
    return {
      headline,
      value: `${monthDiff} months earlier`,
    };
  if (monthDiff < 24)
    return {
      headline,
      value: '1 year earlier',
    };

  return {
    headline,
    value: `${Math.floor(monthDiff / 12)} years earlier`,
  };
};

export const shouldDisplayOffer = ({ paymentStartDate, monthSavings }: StudentLoanData) =>
  paymentStartDate && monthSavings && isBefore(new Date(paymentStartDate), new Date()) && monthSavings > 0;

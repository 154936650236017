import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { addMonths, formatMonthYear } from 'utils/dateUtils';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { StudentLoanSavingsResult } from 'enums/StudentLoanForgivenessFlowResults';
import { FlowComponentType } from 'routes/FlowRouter';

import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { getRepaymentLabel } from 'components/StudentLoanForgiveness/commonCalculations';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';
import CalendlyModal from 'components/StudentLoanForgiveness/Modal/CalendlyModal';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import AssumptionsModal from './AssumptionsModal/AssumptionsModal';

import styles from './StudentLoanSavings.module.scss';

const StudentLoanSavings = ({ flags, navigationInfo, handleNext }: FlowComponentType) => {
  const { monthSavings, currentPath, recommendedPath } = useSelector(getStudentLoanData);

  const {
    monthsToDebtFree: monthsToCurrentPathDebtFree,
    monthlyPayment: currentPathMonthlyPayment,
    totalPayment: currentPathTotalPayment,
  } = currentPath!;
  const {
    monthsToDebtFree: monthsToAssistanceDebtFree,
    monthlyPayment: recommendedPathMonthlyPayment,
    totalPayment: recommendedPathTotalPayment,
  } = recommendedPath!;

  const { headline, value } = getRepaymentLabel(monthSavings ?? 0, currentPath!, recommendedPath!);

  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
  const [assumptionsModalOpen, setAssumptionsModalOpen] = useState(false);

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <CalendlyModal onSchedule={handleNext} open={calendlyModalOpen} onClose={() => setCalendlyModalOpen(false)} />
        <FormNavigation {...navigationInfo} />
        <FormContainer title={headline}>
          <p className={styles.yearsToSaveLabel}>{value}</p>

          <p className={clsx(styles.label, styles.spaced)}>
            You likely qualify for federal student loan forgiveness. Unlock a one-time exemption on your student loans.
            <br />
            <strong>This program is ending soon.</strong>
          </p>

          {monthsToCurrentPathDebtFree !== undefined &&
            monthsToCurrentPathDebtFree > 0 &&
            monthsToAssistanceDebtFree !== undefined &&
            monthsToAssistanceDebtFree > 0 && (
              <>
                <p className={styles.label}>Your estimated payoff:</p>
                <div className={styles.compareWrapper}>
                  <div className={styles.compareTable}>
                    <div className={styles.tableHeader}>
                      <p>Current Path</p>
                    </div>
                    <div className={styles.tableBody}>
                      <div className={styles.tableRow}>
                        <strong>{formatMonthYear(addMonths(new Date(), monthsToCurrentPathDebtFree))}</strong>
                        <p>debt free</p>
                      </div>
                      <div className={styles.tableRow}>
                        <strong>{formatMonetaryAmount(currentPathMonthlyPayment)}</strong>
                        <p>monthly payment</p>
                      </div>
                      <div className={styles.tableRow}>
                        <strong>{formatMonetaryAmount(currentPathTotalPayment)}</strong>
                        <p>total payment</p>
                      </div>
                    </div>
                  </div>
                  <div className={clsx(styles.compareTable, styles.purple)}>
                    <div className={styles.tableHeader}>
                      <p>With Plannery</p>
                    </div>
                    <div className={styles.tableBody}>
                      <div className={styles.tableRow}>
                        <strong>{formatMonthYear(addMonths(new Date(), monthsToAssistanceDebtFree))}</strong>
                        <p>debt free</p>
                      </div>
                      <div className={styles.tableRow}>
                        <strong>{formatMonetaryAmount(recommendedPathMonthlyPayment)}</strong>
                        <p>monthly payment</p>
                      </div>
                      <div className={styles.tableRow}>
                        <strong>{formatMonetaryAmount(recommendedPathTotalPayment)}</strong>
                        <p>total payment</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          <Button className={styles.button} type={ButtonType.Secondary} onClick={() => setAssumptionsModalOpen(true)}>
            Update Assumptions
          </Button>

          <Button className={styles.button} onClick={() => handleNext(StudentLoanSavingsResult.Next)}>
            Next
          </Button>

          <Button type={ButtonType.Inverted} onClick={() => setAreYouSureModalOpen(true)}>
            Not Now
          </Button>

          {assumptionsModalOpen && <AssumptionsModal handleOpen={setAssumptionsModalOpen} />}
          {areYouSureModalOpen && (
            <AreYouSureModal
              onClose={() => setAreYouSureModalOpen(false)}
              onNext={() => handleNext(StudentLoanSavingsResult.Next)}
              onNotNow={() => handleNext(StudentLoanSavingsResult.NotNow)}
            />
          )}
        </FormContainer>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default StudentLoanSavings;

import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import React from 'react';
import { useSelector } from 'react-redux';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { setStudentLoanAssistanceSchedule } from 'thunks';
import { getStudentLoanData } from 'selectors/getStudentLoanData';

type CalendlyModalProps = {
  open: boolean;
  onClose: () => void;
  onSchedule: () => void;
};

const CalendlyModal = ({ open, onClose, onSchedule }: CalendlyModalProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const { applicationId } = useSelector(getStudentLoanData);

  useCalendlyEventListener({
    onEventScheduled: async (event) => {
      analytics.track('Schedule Consultation Confirmed', event);
      await dispatchWithUnwrap(
        setStudentLoanAssistanceSchedule({
          eventUri: event.data.payload.event.uri,
          applicationId: applicationId!,
        }),
      );
      onSchedule();
    },
    onDateAndTimeSelected() {
      analytics.track('Schedule Consultation Date Selected');
    },
  });

  return (
    <PopupModal
      url={process.env.REACT_APP_STUDENT_LOAN_FORGIVNESS_CALENDLY_URL!}
      prefill={{
        name: `${firstName} ${lastName}`,
        email,
        smsReminderNumber: phoneNumber,
        customAnswers: {
          a1: phoneNumber,
        },
      }}
      onModalClose={onClose}
      open={open}
      /*
       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
       */
      rootElement={document.getElementById('root')!}
    />
  );
};

export default CalendlyModal;

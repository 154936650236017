import React, { useState } from 'react';
import Button from 'components/Button';
import Modal from 'components/Common/Modal';
import Input from 'components/Input';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { updateStudentLoanAssistanceData } from 'thunks';
import { getCardData } from 'selectors/getCardData';

import styles from './AssumptionsModal.module.scss';

interface AssumptionsModalProps {
  handleOpen: (show: boolean) => void;
}

const AssumptionsModal = ({ handleOpen }: AssumptionsModalProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useSelector(getCardData);
  const {
    isLoading,
    householdSize: initialHouseholdSize,
    undergraduateLoansPercentage: initialUndergraduateLoansPercentage,
  } = useSelector(getStudentLoanData);
  const [householdSize, setHouseholdSize] = useState(initialHouseholdSize);
  const [undergraduateLoansPercentage, setUndergraduateLoansPercentage] = useState(initialUndergraduateLoansPercentage);

  const saveAssumptions = async () => {
    await dispatchWithUnwrap(
      updateStudentLoanAssistanceData({
        applicationId: applicationId!,
        householdSize: householdSize!,
        undergraduateLoansPercentage: undergraduateLoansPercentage!,
      }),
    );
    handleOpen(false);
  };

  return (
    <Modal title="Update Estimate" closeModal={() => handleOpen(false)}>
      <>
        <p style={{ textAlign: 'center' }} className={styles.label}>
          Estimates are fine here. We'll verify this information later.
        </p>
        <Input
          className={styles.input}
          label="Household size"
          value={householdSize}
          onChange={(e) => setHouseholdSize(Number(e.target.value))}
        />
        <Input
          className={styles.input}
          label="Percentage of undergraduate loans"
          value={undergraduateLoansPercentage}
          onChange={(e) => setUndergraduateLoansPercentage(Number(e.target.value))}
        />

        <p className={styles.label}>Are you currently enrolled in income driven repayment?</p>

        <Button isLoading={isLoading} className={styles.button} onClick={saveAssumptions}>
          Save
        </Button>
      </>
    </Modal>
  );
};

export default AssumptionsModal;

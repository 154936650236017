import React from 'react';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import FormContainer from 'components/LoanForm/FormContainer';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as StudentLoanIcon } from 'images/student-loan.svg';

import styles from './QuestionsWrapper.module.scss';

const QuestionsWrapper = ({ children }: { children: JSX.Element }) => {
  const { totalBalance } = useSelector(getStudentLoanData);

  return (
    <>
      <FormContainer icon={<StudentLoanIcon />} title="You May Qualify for Student&nbsp;Loan&nbsp;Forgiveness">
        <p className={styles.totalLoanLabel}>
          You have <strong>{formatMonetaryAmount(totalBalance)}</strong> in federal student loans.
        </p>

        <div className={styles.questionWrapper}>{children}</div>
      </FormContainer>
    </>
  );
};

export default QuestionsWrapper;
